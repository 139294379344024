nav {
    width: 100%;
    margin: auto;
    display: flex;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    flex-wrap: wrap;

    a {
        flex: 0 1 300px;
        text-decoration: none;
        color: white;

        h2 {
            font-family: "chalk";
            font-weight: normal;
            text-align: center;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}